<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">View Info</h1>
    </div>
    <div class="mt-3 row px-0 mx-0">
      <div class="col-9 px-0">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loader">
          <div slot="header">
            <div class="d-flex justify-content-between align-items-center">
              <h1 class="ellipsis-overflow-text"
                  :title="response.recipe.name">
                {{ response.recipe.name }}
              </h1>
              <router-link class="router-link pr-2" :to="{name: 'admin.manage_recipe.edit' , params: { id: recipeId } }">
                <base-button type="secondary" class="py-2 show-edit-button">
                  Edit
                </base-button>
              </router-link>
            </div>

            <div class="d-flex mt-2">
              <span class="category-name mr-3">{{ getCategoryName }}</span>
              <div class="d-inline-block">
                <p class="show-status-class px-3 py-1"
                   :class="getStatusClass(response.recipe.status)">
                  {{ getStatusAsString(response.recipe.status) }}
                </p>
              </div>
            </div>
          </div>

          <div class="row px-4">
            <div class="col-9 pr-4">
              <span class="mt-2 recipe-detail-heading">RECIPE</span>
              <p class="my-3 overflow-auto" v-html="response.recipe.recipe"></p>
            </div>
            <div class="col-3 recipe-image h-100 text-center py-3" v-if="response.recipe.image_src">
              <p class="">RECIPE IMAGE</p>
              <el-image :src="response.recipe.image_src" lazy/>
            </div>
          </div>

        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton"

export default {
  name: "ShowRecipe",
  components: {
    BackButton,
  },
  data() {
    return {
      loader: false,
      recipeId: this.$route.params.id,
      response: {
        recipe: {...this.$store.getters['ManageRecipeModule/getRecipe']}
      }
    }
  },
  computed: {
    /**
     * Get category name
     * @returns {*|string}
     */
    getCategoryName() {
      if (this.response.recipe.category) {
        return this.response.recipe.category.map(category => category.name).join(', ')
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.getRecipe()
  },
  methods: {
    /**
     * Get status as string
     * @param status
     * @returns {string}
     */
    getStatusAsString(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Get status class
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'inactive-status-background' : 'active-status-background'
    },

    /**
     * Get Recipe
     */
    getRecipe() {
      let vm = this
      vm.loader = true
      const payload = {
        id: vm.recipeId
      }
      vm.$store.dispatch('ManageRecipeModule/_getRequestedRecipe', payload)
        .then(response => {
          vm.response.recipe = {...response.data.data}
        })
        .catch(error => {
          if (error.response.status == 404) {
            vm.$router.push({name: 'admin.manage_recipe'})
            return
          }

          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Recipe',
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped lang="scss">

.category-name {
  font: normal normal 600 17px/23px Open Sans;
  color: #8898AA;
}

.recipe-detail-heading {
  font: normal normal bold 16px/22px Open Sans;
  color: #172B4D;
  text-transform: uppercase;
}

.recipe-image {
  background-color: #edf0f8;
  border-radius: 4px;

  p {
    font: normal normal bold 16px/22px Open Sans;
    color: #172B4D;
  }

  .el-image {
    border-radius: 4px;
  }
}
</style>
