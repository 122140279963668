<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Manage Recipes</h1>
      <div class="d-flex justify-content-end">
        <router-link
          class="ml-2 "
          :to="{ name: 'admin.manage_recipe.store'}">
          <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect">Add New</el-button>
        </router-link>
      </div>
    </div>
    <div v-loading="loaders.manageRecipes" class="my-4 cardStyle">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-3">
        <div v-if="!selectedRows.length" class="d-flex">
          <base-input class="d-inline-block" label="Search" placeholder="Search..."
                      v-model="request.name" type="search" @change="changeSearchAndGetRecipes" @search="changeSearchAndGetRecipes"/>
          <base-input label="Status" class="ml-3">
            <p @click="clearStatusAndGetRecipe" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="w-100 recipes-filter-select-dropdown"
              label="Status"
              placeholder="Status Select"
              v-model="request.status"
              @change="changeSearchAndGetRecipes"
              >
              <el-option
                v-for="option in dropdowns.status"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label">
              </el-option>
            </el-select>
          </base-input>
          <base-input label="Category" name="Category" class="ml-3">
            <p @click="clearCategoryAndGetRecipe" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="w-100 recipes-filter-select-dropdown"
              placeholder="Select Category"
              v-model="request.category"
              name="Category"
              @change="changeSearchAndGetRecipes"
            >
              <el-option
                class="select-primary"
                :key="''"
                :label="'All'"
                :value="''"
              />
              <el-option
                class="select-primary"
                v-for="category in response.categories"
                :key="category.id"
                :label="category.name"
                :value="category.id"
              />
            </el-select>
          </base-input>
        </div>
        <div v-else class="mt-4 ml-4">
          <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                       @click="promptMultiRemove"
                       :loading="loaders.checkboxButton.Delete"
                       :disabled="loaders.contentSection"
          >Delete
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                       @click="handleActivate"
                       :loading="loaders.checkboxButton.Activate"
                       :disabled="loaders.contentSection"
          >Activate
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                       @click="handleDeactivate"
                       :loading="loaders.checkboxButton.DeActive"
                       :disabled="loaders.contentSection"
          >Deactivate
          </base-button>
        </div>
      </div>
      <el-table
        class="table-responsive table-flush checklist-items-table checklist-table"
        header-row-class-name="thead-light"
        width="100%"
        ref="dynamicSectionItemListingTableRef"
        :data="response.manageRecipes"
        @selection-change="sectionListMultipleSelect"
      >
        <el-table-column prop="selected" type="selection" :min-width="10">
        </el-table-column>
        <el-table-column label="RECIPE NAME" :min-width="30">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="CATEGORY" :min-width="30">
          <template slot-scope="scope">
            <div class="">{{ getCategoryName(scope.row.category) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" :min-width="15">
          <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" :min-width="15">
          <div slot-scope="{ $index, row }" class="d-flex">
            <router-link :to="{name: 'admin.manage_recipe.show', params: {id: row.id}}">
              <img class="eye-icon mr-2" src="/img/eye.svg" alt="">
            </router-link>
            <router-link
              :to="{name: 'admin.manage_recipe.edit', params: {id: row.id}}">
              <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
            </router-link>
            <img
              class="pointer"
              @click="handleDelete($index, row)"
              src="/img/icons/buttons/delete.svg"
              alt="Delete"
            />
          </div>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <custom-pagination
            class="pagination-no-border float-right"
            v-model="currentPage"
            :per-page="10"
            :total="totalPages"
            @input="changePage"
          />
        </div>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeRecipe"
        @onRemoveMultiple="removeMultipleRecipes"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui"
import CustomPagination from "@/views/Components/Pagination/CustomPagination"
import BackButton from "@/components/Router/BackButton"

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "ManageRecipes",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    CustomPagination,
    BackButton
  },
  data() {
    return {
      loaders: {
        manageRecipes: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      dropdowns: {
        status: [
          {value: '', label: 'All Recipes'},
          {value: 1, label: 'Active'},
          {value: 0, label: 'Inactive',},
        ],
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      btnDraggable: 'btnDraggable',
      request: {
        name: '',
        status: '',
        category: ''
      },
      response: {
        manageRecipes: [],
        categories: []
      },
      currentPage: 1,
      totalPages: 1,
    }
  },
  computed: {
    /**
     * Extract the ids from selected rows from table
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  },
  mounted() {
    this.getAllCategories()
    this.getAllRecipes()
  },
  methods: {
    /**
     * Get category name
     * @returns {*|string}
     */
    getCategoryName(categories) {
      if (categories) {
        return categories.map(category => category.name).join(', ')
      } else {
        return ''
      }
    },

    /**
     * Get All Recipe Categories
     */
    getAllCategories() {
      let vm = this
      vm.loader = true
      vm.$store.dispatch('ManageRecipeModule/_getAllCategories')
        .then(response => {
          vm.response.categories = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Categories',
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Change page
     * @param item
     */
    changePage(item) {
      this.currentPage = item
      this.getAllRecipes()
    },

    /**
     * Clear status and get recipes
     */
    clearStatusAndGetRecipe() {
      this.currentPage = 1
      this.request.status = ''
      this.getAllRecipes()
    },

    /**
     * Clear category filter and get recipes
     */
    clearCategoryAndGetRecipe() {
      this.currentPage = 1
      this.request.category = ''
      this.getAllRecipes()
    },

    /**
     * Change search and get all recipes
     */
    changeSearchAndGetRecipes() {
      this.currentPage = 1
      this.getAllRecipes()
    },

    /**
     * Get all Recipes
     */
    getAllRecipes() {
      let vm = this
      vm.loaders.manageRecipes = true
      const payload = {
        page: vm.currentPage,
        name: vm.request.name,
        status: vm.request.status,
        category: vm.request.category,
      }
      vm.$store.dispatch('ManageRecipeModule/_getAllRecipes', payload)
        .then(response => {
          vm.response.manageRecipes = response.data.data.data
          vm.currentPage = response.data.data.current_page
          vm.totalPages = response.data.data.total
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Manage Recipes',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.manageRecipes = false
        })
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle the active button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle the deactivate button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Handle delete button event
     * @param index
     * @param row
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Recipe.'
      this.removeId = row.id
    },

    /**
     * Prompt the Remove modal for multiple items
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Recipes.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle event for selecting multiple rows from table
     * @param selectedRows
     */
    sectionListMultipleSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Handle remove multiple items event
     */
    removeMultipleRecipes() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Handle remove single item event
     * @param id
     */
    removeRecipe(id) {
      this.deleteRequest(id)
    },

    /**
     * Delete Recipe(s)
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.manageRecipes = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}
      vm.$store.dispatch('ManageRecipeModule/_deleteRecipe', payload)
        .then(response => {
          vm.removeId = []
          vm.getAllRecipes()
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: 'Recipe Deleted Successfully.'
          })
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Recipe',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.manageRecipes = false
        })
    },

    /**
     * Update the status for selected row(s) from table
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.manageRecipes = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }
      vm.$store.dispatch('ManageRecipeModule/_updateStatusRecipe', payload)
        .then(response => {
          vm.removeId = []
          vm.$notify.success({
            title: 'Success',
            message: 'Status Updated Successfully.'
          })
          vm.getAllRecipes()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status Recipe',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.manageRecipes = false
        })
    },
  }
}
</script>

<style scoped>

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;
}

.clear-txt {
  text-align: left;
  font: normal normal normal 9px/13px Open Sans;
  letter-spacing: 0px;
  color: #BB2255;
  cursor: pointer;
}

.recipes-filter-select-dropdown >>> .el-input__inner {
  height: 45px;
}
</style>
