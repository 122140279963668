<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Add Recipe</h1>
    </div>
    <div v-loading="loader" class="mt-4">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="my-3">
              <span class="basic-info-heading">BASIC INFO</span>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input label="Category*" name="Category" :rules="{required: true}">
                  <el-select
                    class="w-100"
                    v-model="request.recipe.category_id"
                    :rules="{required: true}"
                    name="Category"
                    placeholder=""
                    multiple
                  >
                    <el-option
                      class="select-primary"
                      v-for="category in response.categories"
                      :key="category.id"
                      :label="category.name"
                      :value="category.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{required: true}"
                  name="Recipe Name"
                  label="Recipe Name*"
                  v-model="request.recipe.name"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col equal-height-columns">
                <base-input label="Recipe*" name="Recipe" :rules="{required: true}">
                  <editor
                    name="Recipe"
                    v-model="request.recipe.recipe"
                    :api-key=editorApiKey
                    :init=editorOption
                  />
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <file-input
                  placeholder="Image"
                  name="image"
                  :rules="{extensions:['jpg','jpeg','png']}"
                  label="Upload Image"
                  @change="addImage"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="" class="form-control-label">Add Nutrition</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox
                        label="Yes"
                        border
                        class="checkbox-custom checkbox-custom-bari w-100"
                        :value="!!request.recipe.nt_check"
                        @change="request.recipe.nt_check=Number(!request.recipe.nt_check)"/>
                    </div>
                    <div class="col-6">
                      <el-checkbox
                        label="No"
                        border
                        :value="!request.recipe.nt_check"
                        class="checkbox-custom checkbox-custom-bari w-100"
                        @change="request.recipe.nt_check=Number(!request.recipe.nt_check)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Item Name"
                  label="Item Name"
                  v-model="request.recipe.item_name"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Brand Name"
                  label="Brand Name"
                  v-model="request.recipe.brand_name"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Calories"
                  label="Calories"
                  v-model="request.recipe.nf_calories"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Fat"
                  label="Fat"
                  v-model="request.recipe.nf_total_fat"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Saturated Fat"
                  label="Saturated Fat"
                  v-model="request.recipe.nf_saturated_fat"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Trans Fatty Acid"
                  label="Trans Fatty Acid"
                  v-model="request.recipe.nf_trans_fatty_acid"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Polyunsaturated Fat"
                  label="Polyunsaturated Fat"
                  v-model="request.recipe.nf_polyunsaturated_fat"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Monounsaturated Fat"
                  label="Monounsaturated Fat"
                  v-model="request.recipe.nf_monounsaturated_fat"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Cholesterol"
                  label="Cholesterol"
                  v-model="request.recipe.nf_cholesterol"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Sodium"
                  label="Sodium"
                  v-model="request.recipe.nf_sodium"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Total Carbohydrate"
                  label="Total Carbohydrate"
                  v-model="request.recipe.nf_total_carbohydrate"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Sugars"
                  label="Sugars"
                  v-model="request.recipe.nf_sugars"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Protein"
                  label="Protein"
                  v-model="request.recipe.nf_protein"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Serving Size Quantity"
                  label="Serving Size Quantity"
                  v-model="request.recipe.nf_serving_size_qty"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Serving Size Unit"
                  label="Serving Size Unit"
                  v-model="request.recipe.nf_serving_size_unit"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Serving Weight Gram"
                  label="Serving Weight Gram"
                  v-model="request.recipe.nf_serving_weight_grams"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Type"
                  label="Type"
                  v-model="request.recipe.nf_type"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Total Item"
                  label="Total Item"
                  v-model="request.recipe.nf_totalItem"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="request.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Dietary Fiber"
                  label="Dietary Fiber"
                  v-model="request.recipe.nf_dietary_fiber"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Calcium"
                  label="Calcium"
                  v-model="request.recipe.nf_calcium"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input label="Status">
                  <el-select
                    class="w-100"
                    label="Status"
                    v-model="request.recipe.status">
                    <el-option
                      v-for="option in dropdowns.status"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit()"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          {{ 'Save' }}
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import {store} from "@/vuex/store";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

extend("required", {
    ...required, message: (field) => {
      let name = field.substr(0, 1).toUpperCase() + field.substr(1);
      return `This ${name} is required.`
    }
  }
)

export default {
  name: "AddRecipe",
  components: {
    BackButton
  },
  data() {
    return {
      loader: false,
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      response: {
        categories: []
      },
      request: {
        recipe: {...this.$store.getters['ManageRecipeModule/getRecipe']}
      },
      dropdowns: {
        status: [
          {value: 1, label: 'Active'},
          {value: 0, label: 'Inactive'},
        ],
      },
    }
  },
  mounted() {
    this.getAllCategories()
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Handle image add event
     * @param files
     */
    addImage(files) {
      this.request.recipe.picture = files[0]
    },

    /**
     * Get All Recipe Categories
     */
    getAllCategories() {
      let vm = this
      vm.loader = true
      vm.$store.dispatch('ManageRecipeModule/_getAllCategories')
        .then(response => {
          vm.response.categories = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Categories',
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Handle submit form
     */
    async onSubmit() {
      let vm = this
      const isFormValid = await this.$refs['formValidator'].validate()
      if (isFormValid) {
        vm.storeRecipe()
      } else {
        vm.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

    /**
     * Store Recipe
     */
    storeRecipe() {
      let vm = this
      vm.loader = true
      var formData = new FormData()
      _.each(vm.request.recipe, (value, key) => {
        formData.append(key, value)
      })
      const config = {headers: {'content-type': 'multipart/form-data'}}
      vm.$store.dispatch('ManageRecipeModule/_storeRecipe', {config, formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Recipe Created Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Recipe',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>
.basic-info-heading {
  font: normal normal normal 17px/23px Open Sans;
  color: #8898AA;
}
</style>
