<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Edit Recipe</h1>
    </div>
    <div v-loading="loaders.recipe" class="mt-4">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="my-3">
              <span class="basic-info-heading">BASIC INFO</span>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input label="Category*" name="Category" :rules="{required: true}">
                  <el-select
                    class="w-100"
                    v-model="response.recipe.category_id"
                    :rules="{required: true}"
                    name="Category"
                    placeholder=""
                    multiple
                  >
                    <el-option
                      class="select-primary"
                      v-for="category in response.categories"
                      :key="category.id"
                      :label="category.name"
                      :value="category.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{required: true}"
                  name="Recipe Name"
                  label="Recipe Name*"
                  v-model="response.recipe.name"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col equal-height-columns">
                <base-input label="Recipe*" name="Recipe" :rules="{required: true}">
                  <editor
                    name="Recipe"
                    v-model="response.recipe.recipe"
                    :api-key=editorApiKey
                    :init=editorOption
                  />
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 d-flex">
                <file-input
                  class="w-100"
                  placeholder="Image"
                  name="image"
                  :rules="{extensions:['jpg','jpeg','png']}"
                  label="Upload Image"
                  @change="addImage"
                />
                <el-image
                  lazy
                  v-loading="loaders.image"
                  fit="none"
                  class="img-size pl-2"
                  :class="{'d-none':!imageExist}"
                  :src="response.recipe.image_src"
                  @load="handleLoad"
                  @error="handleLoad"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="" class="form-control-label">Add Nutrition</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox
                        label="Yes"
                        border
                        class="checkbox-custom checkbox-custom-bari w-100"
                        :value="!!response.recipe.nt_check"
                        @change="response.recipe.nt_check=Number(!response.recipe.nt_check)"/>
                    </div>
                    <div class="col-6">
                      <el-checkbox
                        label="No"
                        border
                        :value="!response.recipe.nt_check"
                        class="checkbox-custom checkbox-custom-bari w-100"
                        @change="response.recipe.nt_check=Number(!response.recipe.nt_check)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Item Name"
                  label="Item Name"
                  v-model="response.recipe.item_name"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Brand Name"
                  label="Brand Name"
                  v-model="response.recipe.brand_name"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Calories"
                  label="Calories"
                  v-model="response.recipe.nf_calories"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Fat"
                  label="Fat"
                  v-model="response.recipe.nf_total_fat"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Saturated Fat"
                  label="Saturated Fat"
                  v-model="response.recipe.nf_saturated_fat"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Trans Fatty Acid"
                  label="Trans Fatty Acid"
                  v-model="response.recipe.nf_trans_fatty_acid"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Polyunsaturated Fat"
                  label="Polyunsaturated Fat"
                  v-model="response.recipe.nf_polyunsaturated_fat"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Monounsaturated Fat"
                  label="Monounsaturated Fat"
                  v-model="response.recipe.nf_monounsaturated_fat"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Cholesterol"
                  label="Cholesterol"
                  v-model="response.recipe.nf_cholesterol"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Sodium"
                  label="Sodium"
                  v-model="response.recipe.nf_sodium"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Total Carbohydrate"
                  label="Total Carbohydrate"
                  v-model="response.recipe.nf_total_carbohydrate"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Sugars"
                  label="Sugars"
                  v-model="response.recipe.nf_sugars"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Protein"
                  label="Protein"
                  v-model="response.recipe.nf_protein"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Serving Size Quantity"
                  label="Serving Size Quantity"
                  v-model="response.recipe.nf_serving_size_qty"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Serving Size Unit"
                  label="Serving Size Unit"
                  v-model="response.recipe.nf_serving_size_unit"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Serving Weight Gram"
                  label="Serving Weight Gram"
                  v-model="response.recipe.nf_serving_weight_grams"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Type"
                  label="Type"
                  v-model="response.recipe.nf_type"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  name="Total Item"
                  label="Total Item"
                  v-model="response.recipe.nf_totalItem"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row" v-if="response.recipe.nt_check">
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Dietary Fiber"
                  label="Dietary Fiber"
                  v-model="response.recipe.nf_dietary_fiber"
                  placeholder="">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  :rules="{regex: /^\d+(\.\d{1,})?$/}"
                  name="Calcium"
                  label="Calcium"
                  v-model="response.recipe.nf_calcium"
                  placeholder="">
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input label="Status">
                  <el-select
                    class="w-100"
                    label="Status"
                    v-model="response.recipe.status">
                    <el-option
                      v-for="option in dropdowns.status"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit()"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          {{ 'Update' }}
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {extend} from "vee-validate"
import {required, regex} from "vee-validate/dist/rules"
import BackButton from "@/components/Router/BackButton"
import {store} from "@/vuex/store"
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin"

extend("required", {
    ...required, message: (field) => {
      let name = field.substr(0, 1).toUpperCase() + field.substr(1)
      return `This ${name} is required.`
    }
  }
)

extend("regex", {
    ...regex, message: (field) => {
      let name = field.substr(0, 1).toUpperCase() + field.substr(1)
      return `${name} must be numeric.`
    }
  }
)

export default {
  name: "EditRecipe",
  components: {
    BackButton
  },
  data() {
    return {
      loaders: {
        recipe: false,
        image: false
      },
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      response: {
        categories: [],
        recipe: {...this.$store.getters['ManageRecipeModule/getRecipe']}
      },
      recipeId: this.$route.params.id,
      dropdowns: {
        status: [
          {value: 1, label: 'Active'},
          {value: 0, label: 'Inactive'},
        ],
      },
    }
  },
  computed: {
    /**
     * Computed property to check back picture if exist already
     * @returns {false|string|boolean}
     */
    imageExist() {
      return this.response.recipe.image_src !== '' && this.response.recipe.image_src
    },
  },
  mixins: [
    generateErrorMessageMixin
  ],
  mounted() {
    this.getAllCategories()
    this.getRecipe()
  },
  methods: {
    /**
     * Handles the image load
     */
    handleLoad() {
      this.loaders.image = false
    },

    /**
     * Get Recipe
     */
    getRecipe() {
      let vm = this
      vm.loaders.recipe = true
      const payload = {
        id: vm.recipeId
      }
      vm.$store.dispatch('ManageRecipeModule/_getRequestedRecipe', payload)
        .then(response => {
          vm.response.recipe = {...response.data.data}
        })
        .catch(error => {
          if (error.response.status == 404) {
            vm.$router.push({name: 'admin.manage_recipe'})
            return
          }

          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Recipe',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.recipe = false
        })
    },

    /**
     * Handle image add event
     * @param files
     */
    addImage(files) {
      this.response.recipe.image_src = ''
      this.response.recipe.new_picture = files[0]
    },

    /**
     * Get All Recipe Categories
     */
    getAllCategories() {
      let vm = this
      vm.$store.dispatch('ManageRecipeModule/_getAllCategories')
        .then(response => {
          vm.response.categories = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Categories',
            message: message
          })
        })
    },

    /**
     * Handle submit form
     */
    async onSubmit() {
      let vm = this
      const isFormValid = await this.$refs['formValidator'].validate()
      if (isFormValid) {
        vm.updateRecipe()
      } else {
        vm.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

    /**
     * Update Recipe
     */
    updateRecipe() {
      let vm = this
      vm.loaders.recipe = true
      var formData = new FormData()
      _.each(vm.response.recipe, (value, key) => {
        formData.append(key, value ?? '')
      })
      const config = {headers: {'content-type': 'multipart/form-data'}}
      vm.$store.dispatch('ManageRecipeModule/_updateRecipe', {config, formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Recipe Updated Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Recipe',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.recipe = false
        })
    }
  }
}
</script>

<style scoped>

</style>
